<template>
  <section class="iq-section iq-register">
    <div class="iq-register__logo">
      <img src="../assets/logod.png" alt="">
      <span>猫看拼团</span>
    </div>
    <div class="iq-register__status">
      <div class="iq-register__status-pics">
        <img src="" alt="">
      </div>
      <div class="iq-register__status-desc">
        <p>恭喜您已经是</p>
        <p>「喜屏拼团」分享团长！</p>
      </div>
      <div class="iq-register__status-code">
        <img src="" alt="">
      </div>
      <div class="iq-register__status-tips">
        长按识别二维码，关注公众号
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'DrRegister',
  data () {
    return {
    }
  },
  mounted () {},
  methods: {
    onSubmit () {}
  }
}
</script>

<style lang="scss" scoped>
  .iq-register {
    height: 100%;
    background: #f5f5f5;
    overflow: hidden;

    &__ {

      &logo {
        border-radius: 50%;
        margin: 40px auto 28px;
        text-align: center;

        img,
        span {
          display: block;
          line-height: 1;
        }

        img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          margin: 0 auto 8px;
        }

        span {
          font-size: 18px;
          line-height: 25px;
        }
      }

      &status {
        width: calc( 100% - 24px );
        height: 381px;
        background: #fff;
        margin: 0 auto;
        line-height: 1;
        text-align: center;

        &- {

          &pics {
            padding: 24px 0;

            img {
              display: block;
              width: 36px;
              height: 36px;
              margin: 0 auto;
              background: #00B578;
            }
          }

          &desc {
            padding-bottom: 40px;
            p {
              font-size: 18px;
              color: #333;

              &:nth-child(1) {
                margin-bottom: 12px;
              }
            }
          }

          &code {
            img {
              display: block;
              width: 140px;
              height: 140px;
              margin: 0 auto;
              background: #f5f5f5;
            }
          }

          &tips {
            margin-top: 12px;
            font-size: 13px;
            color: #666;
          }
        }
      }
    }
  }
</style>
